<template>
    <div class="pb-5">
        <v-title title="Customers"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Customers</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createAccount')" :to="{ name: 'AddCustomer' }" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Add new customer
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listAccounts')">
                <div class="row mt-3">
                    <div class="col-6">
                        <quick-search :quickSearch.sync="filters.search" />
                    </div>
                    <div class="col-6">
                        <pagination :pagination="pagination" @change="changeAmountOfItems" />
                    </div>
                </div>

                <merchant-data-table class="shadow" :options="options" :url="url" :quickSearch="filters.search" ref="table" @paginationSet="updatePagination"></merchant-data-table>
            </template>
            <p v-else>
                Not allowed to see this view
            </p>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <div class="mt-3 mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <mercur-button class="btn" @click.native="dialog.isOpen = false">Cancel</mercur-button>
                    <mercur-button class="btn" @click.native="dialogAction" :disabled="isLoading">Confirm</mercur-button>
                </div>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'

export default {
    name: 'CustomersView',
    components: {
        MerchantDataTable,
        QuickSearch,
        Pagination,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.CUSTOMERS.OVERVIEW.replace('{agentId}', this.$store.state.auth.user.accountId),
            isDialogOpen: false,
            activeAccount: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Account Number': {
                        field: 'accountNumber',
                        valueGetter: ({ data }) => data.accountNumber || data.accountId,
                        link: (value, data) => {
                            return {
                                name: 'CustomerView',
                                params: {
                                    applicationId: data.applicationId,
                                    customerId: data.accountId,
                                },
                            }
                        },
                        width: 150,
                    },
                    'First Name': { field: 'firstName', sortable: true, width: 120 },
                    'Last Name': { field: 'lastName', sortable: true, width: 120 },
                    'Email Address': { field: 'email', sortable: true, width: 300 },
                    'Phone Number': { field: 'phone' },
                    'Shop': { field: 'applicationName' },
                    'Group': { field: 'accountSettings.defaultCustomerGroup' },
                    'Revoked': { field: 'dateRevoked' },
                    'accountId': { field: 'accountId', hide: true },
                },
                quickSearchColumns: ['firstName', 'lastName', 'accountNumber', 'email'],
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Revoke',
                            isHidden: ({ data }) => {
                                return data.dateRevoked !== null || !this.hasPermission('revokeAccount')
                            },
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                        {
                            text: 'Reactivate',
                            isHidden: ({ data }) => {
                                return data.dateRevoked === null || !this.hasPermission('reactivateAccount')
                            },
                            onClick: ({ data }) => {
                                this.triggerReactivateDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeAccount = data.accountId
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke access for ${data.firstName} ${data.lastName}?`,
                action: 'REVOKE',
            }
        },
        triggerReactivateDialog (data) {
            this.activeAccount = data.accountId
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to reactivate access for ${data.firstName} ${data.lastName}?`,
            }
        },
        dialogAction () {
            if (this.dialog.action === 'REVOKE') {
                this.revoke()
            } else {
                this.reactivate()
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.REVOKE.replace('{customerId}', this.activeAccount)
            this.isLoading = true
            this.post(url, {}, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
            }).finally(() => {
                this.loading = false
            })
        },

        reactivate () {
            const url = CONFIG.API.ROUTES.CUSTOMERS.REACTIVATE.replace('{customerId}', this.activeAccount)
            this.isLoading = true
            this.post(url, {}, 'Reactivate success').then(() => {
                this.$refs.table.refreshGrid()
            }).finally(() => {
                this.loading = false
            })
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>
